import * as React from "react"
import { connect } from "react-redux"
import { Button, Col, ProgressBar, Row } from "react-bootstrap"

import PlayerVideo from "./player-video.js"
import songData from "../../static/livestream.json"

import { playerbutton } from "./player.module.css"

class Player extends React.Component {
  state = {
    loaded: false,
    currentTime: 0,
    length: 0,
  }

  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.songIndex = 0

    this.getSong = this.getSong.bind(this)
    this.getPrevSong = this.getPrevSong.bind(this)
    this.getNextSong = this.getNextSong.bind(this)
  }

  getSong(song) {
    if (!song) song = songData.library[this.songIndex]
    this.props.setPlayerInfo(song)
    return song
  }

  getPrevSong() {
    this.songIndex = this.songIndex ? this.songIndex - 1 : 0
    return this.getSong(songData.library[this.songIndex])
  }

  getNextSong() {
    this.songIndex =
      this.songIndex + 1 === songData.library.length
        ? this.songIndex
        : this.songIndex + 1
    return this.getSong(songData.library[this.songIndex])
  }

  onEnded(e) {
    const ref = e.target
    const data = this.getNextSong()
    ref.src = data.src
    ref.play()
  }

  componentDidMount() {
    const ref = this.ref.current

    this.getSong()
    this.interval = setInterval(() => {
      this.setState({
        currentTime: ref?.currentTime || 0,
        length: ref?.duration || 0,
      })
    }, 1000)
  }

  componentWillUnmount() {
    this.ref.current.removeEventListener("ended", this.onEnded)
    clearInterval(this.interval)
  }

  componentDidUpdate(prevProps) {
    const { player, setMuted, setPlayerStatus } = this.props
    const { status, volume } = player
    const playerRef = this.ref.current

    if (status === "loading" && prevProps.player.status === "paused") {
      playerRef.src = player.src
      playerRef.volume = volume

      let playPromise = playerRef.play()
      if (playPromise !== undefined)
        playPromise.then(_ => {
          setPlayerStatus({ status: "playing" })
          // Hit play, turn mute off
          setMuted(false)
        })
    } else if (status === "paused" && prevProps.status !== "paused") {
      playerRef.pause()
    } else if (status === "playing" && prevProps.player.src !== player.src) {
      playerRef.src = player.src
      setPlayerStatus({ status: "loading" })
      let playPromise = playerRef.play()
      if (playPromise !== undefined)
        playPromise.then(_ => {
          setPlayerStatus({ status: "playing" })
        })
    }
  }

  render() {
    const { player, setMuted, setPlayerStatus, setVolume } = this.props
    const { muted, status, volume } = player
    const playerRef = this.ref

    // Elapsed Time
    const { currentTime, length } = this.state
    const currentTimeMinutes = Math.floor(currentTime / 60)
    const currentTimeSeconds = Math.floor(currentTime - currentTimeMinutes * 60)
    const timeLeft = length - currentTime
    const timeLeftMinutes = Math.floor(timeLeft / 60)
    const timeLeftSeconds = Math.floor(timeLeft - timeLeftMinutes * 60)

    return (
      <header className="position-relative-zindex">
        <Row>
          <Col xs={12} md={6}>
            <div className="text-start">
              {/* Song Information */}
              <div className="mb-2">
                <p className="mb-0 text-uppercase">{player.title}</p>
                {player.album ? (
                  <small className="text-muted text-uppercase">
                    {player.album}
                  </small>
                ) : null}
              </div>
              {/* Elapsed Time */}
              <div className="row text-muted text-small">
                <div className="col">
                  {currentTimeMinutes}:
                  {currentTimeSeconds < 10
                    ? "0" + currentTimeSeconds
                    : currentTimeSeconds}
                </div>
                <div className="col text-end">
                  -{timeLeftMinutes}:
                  {timeLeftSeconds < 10
                    ? "0" + timeLeftSeconds
                    : timeLeftSeconds}
                </div>
              </div>
              <ProgressBar
                animated
                variant="danger"
                now={(currentTime / length) * 100}
              />
              {/* Controls */}
              <Row>
                <Col>
                  <Button
                    variant="link"
                    className={`${playerbutton} my-1 me-3`}
                    disabled={this.songIndex === 0}
                    onClick={this.getPrevSong}
                  >
                    PREV
                  </Button>
                  {status === "paused" && (
                    <Button
                      variant="link"
                      className={`${playerbutton} my-1`}
                      onClick={() => setPlayerStatus({ status: "loading" })}
                    >
                      PLAY
                    </Button>
                  )}
                  {status === "loading" && (
                    <Button
                      variant="link"
                      className={`${playerbutton} my-1`}
                      disabled
                    >
                      LOADING
                    </Button>
                  )}
                  {status === "playing" && (
                    <Button
                      variant="link"
                      className={`${playerbutton} my-1`}
                      onClick={() => setPlayerStatus({ status: "paused" })}
                    >
                      PAUSE
                    </Button>
                  )}
                  <Button
                    variant="link"
                    className={`${playerbutton} my-1 ms-3`}
                    disabled={this.songIndex === songData.library.length - 1}
                    onClick={this.getNextSong}
                  >
                    NEXT
                  </Button>
                </Col>
                <Col xs="auto" className="text-end">
                  <Button
                    variant="link"
                    className={`${playerbutton} my-1`}
                    onClick={() => {
                      setMuted(!muted)
                      if (!muted) playerRef.current.volume = 0
                      else playerRef.current.volume = volume
                    }}
                  >
                    {muted ? "UNMUTE" : "MUTE"}
                  </Button>
                </Col>
                <audio
                  ref={playerRef}
                  src={player.src}
                  preload="true"
                  onEnded={this.onEnded}
                >
                  <track kind="captions" />
                </audio>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={{ span: 4, offset: 3 }} id="player-video">
            <PlayerVideo />
          </Col>
        </Row>
      </header>
    )
  }
}

const mapStateToProps = ({ player, video }) => ({ player, video })

const mapDispatchToProps = dispatch => ({
  setMuted: muted => dispatch({ type: "PLAYER_UPDATE_MUTE", payload: muted }),
  setPlayerInfo: info =>
    dispatch({ type: "PLAYER_UPDATE_INFO", payload: info }),
  setPlayerStatus: ({ status }) =>
    dispatch({ type: "PLAYER_UPDATE_STATUS", payload: status }),
  setVideo: payload => dispatch({ type: "VIDEO_UPDATE_ID", payload: payload }),
  setVolume: volume =>
    dispatch({ type: "PLAYER_UPDATE_VOLUME", payload: volume }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Player)

import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { marquee } from "./marquee.module.css"

const Marquee = () => {
  // TIMER STUFF
  const calculateTimeLeft = () => {
    const difference = +new Date("Nov 4, 2021 20:00:00-06:00") - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft())

  React.useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval, i) => {
    if (!timeLeft[interval]) {
      return
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}
      </span>
    )
  })

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/marquee.md/" } }
      ) {
        nodes {
          id
          frontmatter {
            text
          }
        }
      }
    }
  `)

  if (data.allMarkdownRemark.nodes[0].frontmatter.text)
    return (
      <p className={marquee}>
        {timerComponents.length ? (
          timerComponents
        ) : (
          <span> {data.allMarkdownRemark.nodes[0].frontmatter.text} </span>
        )}
      </p>
    )
  else return null
}

export default Marquee

import * as React from "react"

import Socials from "./socials"

const Footer = () => {
  let year = new Date().getFullYear()

  return (
    <footer className="footer text-center mt-3">
      <Socials />
      <p className="small text-muted">
        <span className="border-bottom p-1">© {year} Renowned</span>
        <br />
        <a
          href="https://www.instagram.com/steeze_nasty/"
          target="_blank"
          rel="noreferrer"
          className="small text-muted"
        >
          Website by Alexander Houy
        </a>
      </p>
    </footer>
  )
}

export default Footer

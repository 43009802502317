import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
  let newState
  switch (action.type) {
    case "PLAYER_UPDATE_INFO":
      if (!action.payload.src) action.payload.src = action.payload.file
      newState = { ...state.player, ...action.payload }
      return { ...state, player: newState }
    case "PLAYER_UPDATE_STATUS":
      newState = { ...state.player, status: action.payload }
      return { ...state, player: newState }
    case "PLAYER_UPDATE_VOLUME":
      newState = { ...state.player, volume: action.payload }
      return { ...state, player: newState }
    case "PLAYER_UPDATE_MUTE":
      newState = { ...state.player, muted: action.payload }
      return { ...state, player: newState }
    case "VIDEO_UPDATE_ID":
      return { ...state, video: { ...action.payload } }
    default:
      return state
  }
}

const initialState = {
  player: {
    src: "",
    status: "paused",
    volume: 0.3,
    muted: false,
  },
  video: { id: "", timestamp: 0 },
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore

import useWrapPageElement from "./src/components/common/use-wrap-page-element.js"

import "bootswatch/dist/darkly/bootstrap.min.css"
import "./src/styles/global.css"

const handleTransition = () => {
  let content = document.getElementById("wrapper")
  if (content) {
    content.classList.remove("animate_content")
  }
}

export const onInitialClientRender = () => {
  let loader = document.getElementById("___loader")
  loader.classList.add("animate_content")
  loader.style.display = "flex"
}

export const onClientEntry = handleTransition

export const onPreRouteUpdate = handleTransition

export const onRouteUpdateDelayed = handleTransition

export const onRouteUpdate = () => {
  setTimeout(() => {
    let loader = document.getElementById("___loader")
    loader.classList.remove("animate_content")
    loader.style.display = "none"
  }, 1000)

  let content = document.getElementById("wrapper")
  if (content) {
    content.classList.add("animate_content")
  }
}

export const wrapPageElement = useWrapPageElement

import * as React from "react"
import {
  FaBandcamp,
  FaInstagram,
  FaSoundcloud,
  FaSpotify,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa"

const socials = [
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/itsrenowned",
  },
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/itsrenowned/",
  },
  {
    icon: <FaSpotify />,
    url: "https://open.spotify.com/artist/4latiHSC0MojIIhqL2ZRot?si=UKWX41uQSqq97O7z1dk8bQ&dl_branch=1",
  },
  {
    icon: <FaYoutube />,
    url: "https://www.youtube.com/channel/UCFhoVNg0PRFuznCzu1GtrdA/videos",
  },
  {
    icon: <FaSoundcloud />,
    url: "https://soundcloud.com/itsrenowned/",
  },
  {
    icon: <FaBandcamp />,
    url: "https://renowned.bandcamp.com/",
  },
]

const Socials = () => {
  return (
    <p style={{ fontSize: "25px" }}>
      {socials.map((social, i) => (
        <a
          key={i}
          href={social.url}
          target="_blank"
          rel="noreferrer"
          className="mx-2"
          style={{ color: "white" }}
        >
          {social.icon}
        </a>
      ))}
    </p>
  )
}

export default Socials

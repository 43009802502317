import React from "react"
import { connect } from "react-redux"

class PlayerVideo extends React.Component {
  constructor(props) {
    super(props)

    this.onPlayerReady = this.onPlayerReady.bind(this)
    this.onPlayerStateChange = this.onPlayerStateChange.bind(this)
  }

  onPlayerReady(e) {
    e.target.seekTo(this.props.video.timestamp)
    e.target.playVideo()
  }

  onPlayerStateChange(e) {
    if (e.data === 0) {
      this.player = null
      this.props.setVideo({})
    }
  }

  loadVideo() {
    this.player = new window.YT.Player(`youtube-${this.props.video.id}`, {
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange,
      },
    })
  }

  componentDidMount() {
    if (this.props.video.id) this.loadVideo()
  }

  componentDidUpdate(prevProps) {
    if (this.props.video.id && prevProps.video.id !== this.props.video.id)
      this.loadVideo()
  }

  render() {
    return (
      <div
        className={
          "ratio ratio-16x9 mb-1 fade" +
          (this.props.video.id ? " show" : " d-none")
        }
      >
        {this.props.video.id && (
          <iframe
            id={`youtube-${this.props.video.id}`}
            src={`https://www.youtube.com/embed/${this.props.video.id}?enablejsapi=1`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ video }) => ({ video })

const mapDispatchToProps = dispatch => ({
  setVideo: payload => dispatch({ type: "VIDEO_UPDATE_ID", payload: payload }),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayerVideo)

import React from "react"
import { Container } from "react-bootstrap"

import Footer from "../footer.js"
import Marquee from "../marquee.js"
import Player from "../player.js"

const useWrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <>
      <main id="wrapper">
        <div id="container">
          <Container>
            {/* <Player /> */}
            {element}
            <Footer />
          </Container>
        </div>
      </main>
      <Marquee />
    </>
  )
}

export default useWrapPageElement
